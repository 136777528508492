import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
const useStyles = makeStyles(theme => ({
  links: {
    [theme.breakpoints.up('sm')]: {
      gap: '16px'
    }
  },
  container: {
    maxWidth: '1536px',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      padding: '40px'
    }
  }
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <Box
      sx={{ padding: '10px' }}
      className={classes.container}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <a href="https://jurat.io/" target="_blank">
          <img src="/images/icons/footer-logo.svg" alt="" />
        </a>
        <Box sx={{ display: 'flex', gap: '12px' }} className={classes.links}>
          <a href="https://twitter.com/JuratNetwork" target="_blank">
            <img src="/images/icons/twitter.svg" />
          </a>
          <a href="https://www.linkedin.com/company/jurat/" target="_blank">
            <img src="/images/icons/linkedin.svg" />
          </a>
          <a href="https://discord.gg/aVuRMNS4pE" target="_blank">
            <img src="/images/icons/discord.svg" />
          </a>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          fontSize: 14,
          flexDirection: 'column',
          marginTop: '36px'
        }}
      >
        <Typography component="h6" variant="h6">
          Powered by Jurat
        </Typography>
        <Box sx={{ display: 'flex', gap: '4px' }}>
          <a href="https://jurat.io/privacy-policy/" target="_blank">
            <Typography
              component="h6"
              variant="h6"
              style={{ color: '#4169e1' }}
            >
              Privacy Policy
            </Typography>
          </a>
          <Typography component="h6" variant="h6" style={{ color: '#4169e1' }}>
            and
          </Typography>
          <a href="https://jurat.io/terms-conditions/" target="_blank">
            <Typography
              component="h6"
              variant="h6"
              style={{ color: '#4169e1' }}
            >
              Terms & Conditions
            </Typography>
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
