import uuid from 'uuid/v1';
import moment from 'moment';
import { colors } from '@material-ui/core';
import mock from 'src/utils/mock';

mock.onGet('/api/calendar').reply(200, {
  draft: [],
  events: [
    {
      id: uuid(),
      title: 'Call Samantha',
      desc: 'Inform about new contract',
      color: colors.green['700'],
      start: moment('2020-07-01 16:55:00'),
      end: moment('2020-07-01 17:02:00'),
      staff: 'adsfasdfasdf'
    },
    {
      id: uuid(),
      title: 'Callaaaa',
      desc: 'Inform 222222',
      color: colors.green['700'],
      start: moment('2020-07-01 19:55:00'),
      end: moment('2020-07-01 20:02:00'),
      staff: 'bbbbbb'
    },
    {
      id: uuid(),
      title: 'Callbbb',
      desc: 'Inform 222222',
      color: colors.green['700'],
      start: moment('2020-07-01 22:55:00'),
      end: moment('2020-07-01 23:02:00'),
      staff: 'cccccccc'
    },
    {
      id: uuid(),
      title: 'Callccc',
      desc: 'Inform 222222',
      color: colors.green['700'],
      start: moment('2020-07-01 23:21:00'),
      end: moment('2020-07-01 23:22:00'),
      staff: 'ddd'
    },
    {
      id: uuid(),
      title: 'Callddd',
      desc: 'Inform 3333',
      color: colors.green['700'],
      start: moment('2020-07-01 23:23:00'),
      end: moment('2020-07-01 23:24:00')
    },
    {
      id: uuid(),
      title: 'Calleee',
      desc: 'Inform 3333',
      color: colors.green['700'],
      start: moment('2020-07-01 23:26:00'),
      end: moment('2020-07-01 23:27:00')
    },
    {
      id: uuid(),
      title: 'Callfff',
      desc: 'Inform 3333',
      color: colors.green['700'],
      start: moment('2020-07-01 23:46:00'),
      end: moment('2020-07-01 23:56:00')
    },
    {
      id: uuid(),
      title: 'Meet with IBM',
      desc: 'Discuss about new partnership',
      start: moment('2020-07-03 08:55:00'),
      end: moment('2020-07-04 15:02:00')
    },
    {
      id: uuid(),
      title: 'SCRUM Planning',
      desc: 'Prepare documentation',
      allDay: true,
      start: moment('2020-07-14 16:55:00')
    },
    {
      id: uuid(),
      title: 'Beign SEM',
      desc: 'Meet with team to discuss',
      allDay: true,
      start: moment('2020-07-18 07:00:00')
    },
    {
      id: uuid(),
      title: 'Fire John',
      desc: 'Sorry, John',
      color: colors.green['700'],
      start: moment('2020-07-20 08:55:00'),
      end: moment('2020-07-20 09:30:00')
    },
    {
      id: uuid(),
      title: 'Call Alex',
      desc: 'Discuss about the new project',
      allDay: true,
      start: moment('2020-07-30 08:00:00')
    },
    {
      id: uuid(),
      title: 'Visit Samantha',
      color: colors.green['700'],
      desc: 'Get a new quote for the payment processor',
      start: moment('2020-07-30 08:00:00'),
      end: moment('2020-07-30 09:00:00')
    }
  ]
});
