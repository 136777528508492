import axios from 'axios';

import { SERVER_URL } from './env';

const instance = axios.create({
  baseURL: SERVER_URL,
  headers: {}
});

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token || '';
  return config;
});

instance.interceptors.response.use(
  response => response,
  error => {
    console.log('axios error: ', error);
    throw error;
  }
);

export default instance;
