const mintRequest = '/ord/mintRequest';
const generateReceiveAddress = '/ord/generateReceiveAddress';
const mintRequestStatus = '/ord/mintRequestStatus';

const Endpoints = {
  mintRequest,
  generateReceiveAddress,
  mintRequestStatus
};

export default Endpoints;
