import React, { useContext, createContext } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const defaultToastContext = {
  type: 'success',
  content: '',
  autoHideDuration: 4000
};
const ToastContext = createContext(defaultToastContext);

function ToastProvider({ children }) {
  const [showAlert, setShowAlert] = useState(false);
  const [type, setType] = useState(defaultToastContext.type);
  const [content, setContent] = useState(defaultToastContext.content);
  const [autoHideDuration, setAutoHideDuration] = useState(
    defaultToastContext.autoHideDuration
  );

  const toast = useCallback((type, content, autoHideDuration) => {
    setType(type);
    setContent(content);
    setAutoHideDuration(autoHideDuration);
    setShowAlert(true);
  }, []);

  useEffect(() => {
    if (!showAlert) return;
    setTimeout(() => setShowAlert(false), autoHideDuration);
  }, [showAlert]);

  return (
    <ToastContext.Provider
      value={{
        toast
      }}
    >
      {children}
      <Snackbar open={showAlert} autoHideDuration={autoHideDuration}>
        <Alert severity={type}>{content}</Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
}

export const useToast = () => {
  return useContext(ToastContext);
};

export default ToastProvider;
