export default {
  colorSecondary: {
    color: 'white',
    '& .MuiSvgIcon-root': {
      fill: 'black'
    },
    '&$checked': {
      color: 'black'
    }
  }
};
