const {
  NODE_ENV,
  REACT_APP_SERVER_URL: SERVER_URL,
  REACT_APP_ETHEREUM_USDC_ADDRESS: ETHEREUM_USDC_ADDRESS,
  REACT_APP_POLYGON_USDC_ADDRESS: POLYGON_USDC_ADDRESS,
  REACT_APP_GOERLI_USDC_ADDRESS: GOERLI_USDC_ADDRESS,
  REACT_APP_ETHEREUM_USDT_ADDRESS: ETHEREUM_USDT_ADDRESS,
  REACT_APP_POLYGON_USDT_ADDRESS: POLYGON_USDT_ADDRESS,
  REACT_APP_GOERLI_USDT_ADDRESS: GOERLI_USDT_ADDRESS,
  REACT_APP_ETHEREUM_CHAIN_ID: ETHEREUM_CHAIN_ID,
  REACT_APP_POLYGON_CHAIN_ID: POLYGON_CHAIN_ID,
  REACT_APP_GOERLI_CHAIN_ID: GOERLI_CHAIN_ID,
  REACT_APP_RECIPIENT_ADDRESS: RECIPIENT_ADDRESS,
  REACT_APP_MAILCHIMP_API_KEY: MAILCHIMP_API_KEY,
  REACT_APP_MAILCHIMP_LIST_ID: MAILCHIMP_LIST_ID,
} = process.env;
console.log(NODE_ENV);
export {
  NODE_ENV,
  SERVER_URL,
  RECIPIENT_ADDRESS,
  ETHEREUM_USDC_ADDRESS,
  POLYGON_USDC_ADDRESS,
  GOERLI_USDC_ADDRESS,
  ETHEREUM_USDT_ADDRESS,
  POLYGON_USDT_ADDRESS,
  GOERLI_USDT_ADDRESS,
  ETHEREUM_CHAIN_ID,
  POLYGON_CHAIN_ID,
  GOERLI_CHAIN_ID,
  MAILCHIMP_API_KEY,
  MAILCHIMP_LIST_ID,
};
