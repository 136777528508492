import uuid from 'uuid/v1';
import moment from 'moment';
import { colors } from '@material-ui/core';
import mock from 'src/utils/mock';

mock.onGet('/api/mail').reply(200, {
  emails: [
    {
      id: uuid(),
      seen: true,
      favorited: true,
      labels: [
        {
          text: 'Business',
          color: colors.blue[600]
        },
        {
          text: 'Personal',
          color: colors.orange[600]
        }
      ],
      subject: 'Interested in your benze',
      message: `
Hello sir, How are you?

I'd like to purchase new brand Benze? And I want to check your air filter and spark plugs periodically.
 
When would it be convenient for our appointment?

Kind regards,

Ekaterina Tankova
      `,
      receiver: {
        name: 'Ekaterina Tankova',
        email: 'ekaterina.tankova@dealer.io',
        avatar: '/images/avatars/avatar_2.png'
      },
      created_at: moment()
    },
    {
      id: uuid(),
      seen: false,
      favorited: false,
      labels: [],
      subject: 'Amazing work',
      message:
        "Hey, nice projects! I really liked the one in react. What's your quote on kinda similar project?",
      receiver: {
        name: 'Adam Denisov',
        email: 'adam.denisov@dealer.io',
        avatar: '/images/avatars/avatar_7.png'
      },
      created_at: moment()
    },
    {
      id: uuid(),
      seen: false,
      favorited: false,
      subject: 'Flight reminder',
      labels: [
        {
          text: 'Work',
          color: colors.green[600]
        }
      ],
      message:
        'Dear Shen, Your flight is coming up soon. Please don’t forget to check in for your scheduled flight.',
      receiver: {
        name: 'Clarke Gillebert',
        avatar: '/images/avatars/avatar_6.png'
      },
      created_at: moment()
    },
    {
      id: uuid(),
      seen: true,
      favorited: true,
      labels: [],
      subject: 'Posible candidates for the position',
      message:
        'My market leading client has another fantastic opportunity for an experienced Software Developer to join them on a heavily remote basis',
      receiver: {
        name: 'Cao Yu',
        avatar: '/images/avatars/avatar_3.png'
      },
      created_at: moment()
    }
  ]
});
