import ETHEREUM_USDC_ABI from './abi/ethereum/usdc.json';
import ETHEREUM_USDT_ABI from './abi/ethereum/usdt.json';
import GOERLI_USDC_ABI from './abi/goerli/usdc.json';
import GOERLI_USDT_ABI from './abi/goerli/usdt.json';
import POLYGON_USDC_ABI from './abi/polygon/usdc.json';
import POLYGON_USDT_ABI from './abi/polygon/usdt.json';

export const SOLD_DISPOSED = 'sold disposed';
export const DRAFT_TYPE = 'draft';
export const REGISTERED_TYPE = 'registered';
export const ASSET_NUM_PREFIX = 'FA-';
export const ASSET_NUM_LENGTH = 4;
export const DEFAULT_ASSET_NUM = 'FA-0001';
export const DEPRECIATION_METHODS = [
  'No Depreciation',
  'Straight Line',
  'Diminishing Value',
  'Full Depreciation as Purchase'
];
export const OWNER_ROLE = 'Owner';

/* Validation rule messages */

export const REQUIRED = 'This field is required';
export const NUMBER_TYPE = 'Incorrect number format';

// export const INVALID_DATE = 'Date format is incorrect';

export let minLength = length => {
  return `Minimal allowed length: ${length} chars`;
};

export let maxLength = length => {
  return `Maximal allowed length: ${length} chars`;
};

export let minNumber = minimum => {
  return `Minimal allowed value: ${minimum}`;
};

export let maxNumber = maximum => {
  return `Maximal allowed value: ${maximum}`;
};

export const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

export const FEE_TYPES = {
  JTC: {
    symbol: 'JTC',
    amount: '0.01'
  },
  USDC: {
    symbol: 'USDC',
    amount: '25'
  },
  USDT: {
    symbol: 'USDT',
    amount: '25'
  }
};

export const ABI = {
  "goerli": {
    "usdc": GOERLI_USDC_ABI,
    "usdt": GOERLI_USDT_ABI,
  },
  "ethereum": {
    "usdc": ETHEREUM_USDC_ABI,
    "usdt": ETHEREUM_USDT_ABI,
  },
  "polygon": {
    "usdc": POLYGON_USDC_ABI,
    "usdt": POLYGON_USDT_ABI,
  }
}

export const SELECTED_NETWORK_LOCAL_STORAGE_KEY = "selected-network";