import React, { useCallback, useState, useContext, createContext } from 'react';
import { FEE_TYPES } from 'src/utils/constants';
import { mintRequestForImage, mintRequestForText } from 'src/apis';

const defaultStepContext = {
  page: '1_1',
  ordinalType: 'image', // image | text | license
  ordinalData: '',
  license: {
    type: 'Default',
    customAddress: '',
    terms: {
      exclusive: true,
      royalty: {
        isReserved: false,
        amount: 0,
        address: ''
      }
    }
  },
  ordinalRecipientAddress: '',
  jtcFeeAddress: '',
  networkFee: FEE_TYPES.JTC,
  transactionId: '',
  chainId: 5,
  isMintCompleted: true,
  requestID: '',
  revealTxHash: '',
  inscriptionId: '',
};

const StepContext = createContext(defaultStepContext);

export const StepProvider = ({ children }) => {
  const [page, setPage] = useState(defaultStepContext.page);
  const [ordinalType, setOrdinalType] = useState(
    defaultStepContext.ordinalType
  );
  const [ordinalData, setOrdinalData] = useState(
    defaultStepContext.ordinalData
  );
  const [license, setLicense] = useState(defaultStepContext.license);
  const [ordinalRecipientAddress, setOrdinalRecipientAddress] = useState(
    defaultStepContext.ordinalRecipientAddress
  );
  const [networkFee, setNetworkFee] = useState(defaultStepContext.networkFee);
  const [isMintCompleted, setMintCompleted] = useState(
    defaultStepContext.isMintCompleted
  );
  const [jtcFeeAddress, setJtcFeeAddress] = useState(
    defaultStepContext.jtcFeeAddress
  );
  const [requestID, setRequestID] = useState(defaultStepContext.requestID);
  const [revealTxHash, setRevealTxHash] = useState(defaultStepContext.revealTxHash);
  const [inscriptionId, setInscriptionId] = useState(defaultStepContext.inscriptionId);
  const [transactionId, setTransactionId] = useState(defaultStepContext.transactionId);
  const [chainId, setChainId] = useState(defaultStepContext.chainId);
  /**
   * Submit data to call mint API
   */
  const submitData = useCallback(async () => {
    // log submission data
    const mintData = {
      ordinalType,
      ordinalData,
      license,
      ordinalRecipientAddress,
      networkFee
    };
    console.log({ mintData });
    /**
     * @TODO do API calls here
     */
    if (ordinalType === 'image' || ordinalType === 'license') {
      console.log("aaaaa");
      console.log(ordinalData);
      console.log(networkFee);
      console.log(ordinalRecipientAddress);
      console.log(ordinalType);
      console.log(license);
      const response = await mintRequestForImage({
        file: ordinalData,
        paymentType: networkFee.symbol,
        paymentAmount: networkFee.amount,
        srcWallet: networkFee.symbol === 'USDC' ? null : jtcFeeAddress,
        destWallet: ordinalRecipientAddress,
        // license options
        licenseType: ordinalType === 'license' ? 'License' : license.type,
        licenseTermAddCantBeEvil: license.terms.addCantBeEvil,
        licenseTermAddReserveCCO: license.terms.reserveCCO,
        licenseTermAddExclusive: license.terms.exclusive,
        // royalty info
        royaltyIsReserved: license.terms.royalty.isReserved,
        royaltyAmountPercent: license.terms.royalty.amount,
        royaltyPayToAddress: license.terms.royalty.address,
        transactionId: networkFee.symbol === 'USDC' ? transactionId : undefined,
        chainId: networkFee.symbol === 'USDC' ? chainId : undefined,
        inscriptionId: license.customAddress,
      });
      setRequestID(response);
    } else if (ordinalType === 'text') {
      console.log('text ordinal mint');
      const response = await mintRequestForText({
        paymentType: networkFee.symbol,
        paymentAmount: networkFee.amount,
        srcWallet: networkFee.symbol === 'USDC' ? null : jtcFeeAddress,
        destWallet: ordinalRecipientAddress,
        txtContent: ordinalData,
        // license options
        licenseType: license.type,
        licenseTermAddCantBeEvil: license.terms.addCantBeEvil,
        licenseTermAddReserveCCO: license.terms.reserveCCO,
        licenseTermAddExclusive: license.terms.exclusive,
        // royalty info
        royaltyIsReserved: license.terms.royalty.isReserved,
        royaltyAmountPercent: license.terms.royalty.amount,
        royaltyPayToAddress: license.terms.royalty.address,
        transactionId: networkFee.symbol === 'USDC' ? transactionId : undefined,
        chainId: networkFee.symbol === 'USDC' ? chainId : undefined,
        inscriptionId: license.customAddress,
      });
      setRequestID(response);
    }
  }, [ordinalType, ordinalData, license, ordinalRecipientAddress, networkFee, jtcFeeAddress, transactionId, chainId, inscriptionId]);

  return (
    <StepContext.Provider
      value={{
        page,
        setPage,
        ordinalType,
        setOrdinalType,
        ordinalData,
        setOrdinalData,
        license,
        setLicense,
        ordinalRecipientAddress,
        setOrdinalRecipientAddress,
        networkFee,
        setNetworkFee,
        isMintCompleted,
        setMintCompleted,
        jtcFeeAddress,
        setJtcFeeAddress,
        requestID,
        setRequestID,
        revealTxHash,
        setRevealTxHash,
        inscriptionId,
        setInscriptionId,
        submitData,
        transactionId,
        setTransactionId,
        chainId,
        setChainId,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export const useStepContext = () => {
  return useContext(StepContext);
};
