import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  nav: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  navWrapper: {
    [theme.breakpoints.down('sm')]: {
      columnGap: '12px',
      position: 'relative',
    }
  }
}));

const Header = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const goToExplorer = async () => {
    try {
      await fetch("https://ordinals.jurat.io/");
      window.location.href = "https://ordinals.jurat.io/";
    } catch (error) {
      window.location.href = "/maintenance";
    }
  }
  const goToMinting = async () => {
    try {
      await fetch("https://minting.jurat.io/");
      window.location.href = "https://minting.jurat.io/";
    } catch (error) {
      window.location.href = "/maintenance";
    }
  }
  const goToBond = async () => {
    try {
      await fetch("https://ordinals.jurat.io/bond");
      window.location.href = "https://ordinals.jurat.io/bond";
    } catch (error) {
      window.location.href = "/maintenance";
    }
  }
  const goToGoats = async () => {
    try {
      await fetch("https://ordinals.jurat.io/goats");
      window.location.href = "https://ordinals.jurat.io/goats";
    } catch (error) {
      window.location.href = "/maintenance";
    }
  }
  const goToFaqs = async () => {
    try {
      await fetch("https://ordinals.jurat.io/faq");
      window.location.href = "https://ordinals.jurat.io/faq";
    } catch (error) {
      window.location.href = "/maintenance";
    }
  }
  const goToContact = async () => {
    try {
      await fetch("https://ordinals.jurat.io/contact");
      window.location.href = "https://ordinals.jurat.io/contact";
    } catch (error) {
      window.location.href = "/maintenance";
    }
  }
  
  return (
    <Box
      sx={{
        paddingX: '32px',
        paddingY: '24px',
        display: 'flex',
        position: 'relative',
        gap: '1rem',
        alignItems: 'center',
        '& a': {
          fontSize: '1rem',
          color: 'black',
          fontWeight: 700,
          textDecoration: 'none'
        }
      }}
      className={classes.nav}
    >
      <a href="/" style={{ zIndex: '1' }}>
        <img src="/images/logos/logo.png" alt="logo" />
      </a>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          columnGap: '48px',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className={classes.navWrapper}
      >
        <a style={{ cursor: 'pointer' }} onClick={goToExplorer}>
          <Typography component="h4" variant="h5" style={{ fontWeight: pathname === '/maintenance' ? 700 : 400 }}>
            Explorer
          </Typography>
        </a>
        <a style={{ cursor: 'pointer' }} onClick={goToMinting}>
          <Typography component="h4" variant="h5" style={{ fontWeight: pathname === '/' ? 700 : 400 }}>
            Minting
          </Typography>
        </a>
        {/* <a style={{ cursor: 'pointer' }} onClick={goToBond}>
          <Typography component="h4" variant="h5" style={{ fontWeight: pathname === '/bond' ? 700 : 400 }}>
            Bond
          </Typography>
        </a> */}
        <a style={{ cursor: 'pointer' }} onClick={goToGoats} className="nav-link">
          <Typography component="h4" variant="h5" style={ { fontWeight: pathname === '/goats' ? 700 : 400 }}>
            Goats
          </Typography>
        </a>
        <a style={{ cursor: 'pointer' }} onClick={goToFaqs} className="nav-link">
          <Typography component="h4" variant="h5" style={{ fontWeight: pathname === '/faqs' ? 700 : 400 }}>
            How to Guide
          </Typography>
        </a>
        <a style={{ cursor: 'pointer' }} onClick={goToContact} className="nav-link">
          <Typography component="h4" variant="h5" style={{ fontWeight: pathname === '/contact' ? 700 : 400 }}>
            Contact
          </Typography>
        </a>
      </Box>
    </Box>
  );
};

export default Header;
