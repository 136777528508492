import axios from '../helpers/axios';
import Endpoints from './endpoints';
import FormData from 'form-data';

export const generateReceiveAddress = async () => {
  const { data } = await axios.get(Endpoints.generateReceiveAddress);
  return data?.address ?? '';
};

export const mintRequestForText = async ({
  paymentType,
  paymentAmount,
  srcWallet,
  destWallet,
  txtContent,
  // license options
  licenseType,
  licenseTermAddCantBeEvil,
  licenseTermAddReserveCCO,
  licenseTermAddExclusive,
  // royalty info
  royaltyIsReserved,
  royaltyAmountPercent,
  royaltyPayToAddress,
  transactionId,
  chainId,
  inscriptionId
}) => {
  const formData = new FormData();
  formData.append(
    'mintRequestTxt',
    JSON.stringify({
      paymentType,
      paymentAmount,
      srcWallet,
      destWallet,
      txtContent,
      txtContentType: 'txt',
      licenseType,
      licenseTermAddCantBeEvil,
      licenseTermAddReserveCCO,
      licenseTermAddExclusive,
      royaltyIsReserved,
      royaltyAmountPercent,
      royaltyPayToAddress,
      transactionId,
      chainId,
      licenseInscriptionId: inscriptionId
    })
  );
  const requestID = await axios.post(Endpoints.mintRequest, formData, {
    headers: {
      accept: 'application/json;charset=UTF-8',
      'Content-Type': 'multipart/form-data'
    }
  });
  return requestID?.data?.mintRequestId;
};

export const mintRequestForImage = async ({
  file,
  paymentType,
  paymentAmount,
  srcWallet,
  destWallet,
  // license options
  licenseType,
  licenseTermAddCantBeEvil,
  licenseTermAddReserveCCO,
  licenseTermAddExclusive,
  // royalty info
  royaltyIsReserved,
  royaltyAmountPercent,
  royaltyPayToAddress,
  // usdc payment
  transactionId,
  chainId,
  inscriptionId
}) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append(
    'mintRequestTxt',
    JSON.stringify({
      paymentType,
      paymentAmount,
      srcWallet,
      destWallet,
      txtContent: null,
      txtContentType: null,
      licenseType,
      licenseTermAddCantBeEvil,
      licenseTermAddReserveCCO,
      licenseTermAddExclusive,
      royaltyIsReserved,
      royaltyAmountPercent,
      royaltyPayToAddress,
      transactionId,
      chainId,
      licenseInscriptionId: inscriptionId
    })
  );
  console.log(formData.get('mintRequestTxt'));
  console.log(formData.get('file'));
  const requestID = await axios.post(Endpoints.mintRequest, formData, {
    headers: {
      accept: 'application/json;charset=UTF-8',
      'Content-Type': 'multipart/form-data'
    }
  });
  return requestID?.data?.mintRequestId;
};

export const getMintRequestStatus = async mintRequestId => {
  const statusResponse = await axios.get(
    `${Endpoints.mintRequestStatus}?mintRequestId=${mintRequestId}`
  );
  console.log({ statusResponse });
  return statusResponse?.data;
};
