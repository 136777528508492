import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { Redirect } from 'react-router';
import Cookies from 'src/helpers/Cookies';
import Header from 'src/views/MintSteps/components/Header';
import Footer from 'src/views/MintSteps/components/Footer';

const useStyles = makeStyles(theme => ({
  container: {
    // minHeight: '100vh',
    display: 'flex',
    background: '#F6F5FF',
    backgroundImage: 'url("./images/bg.png")',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    flexGrow: 1,
    maxWidth: '1536px',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0
    },
  }
}));

function Dashboard({ route }) {
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const getCookie = () => {
    let cookie = Cookies.getCookie('token');
    return cookie;
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>
          <Header />
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
      <Footer />
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
