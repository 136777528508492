/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { lazy } from 'react';
import DashboardLayout from './layouts/Dashboard';
import AuthLayout from './layouts/Auth';
import { Redirect } from 'react-router';
import React from 'react';

export default [
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      }
    ]
  },
  {
    path: '/auth',
    exact: true,
    component: () => <Redirect to="/" />
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(() => import('src/views/index'))
      },
      {
        path: '/bond',
        exact: true,
        component: lazy(() => import('src/views/bond'))
      },
      {
        path: '/goats',
        exact: true,
        component: lazy(() => import('src/views/goats'))
      },
      {
        path: '/faqs',
        exact: true,
        component: lazy(() => import('src/views/faqs'))
      },
      {
        path: '/contact',
        exact: true,
        component: lazy(() => import('src/views/contact'))
      },
      {
        path: '/maintenance',
        exact: true,
        component: lazy(() => import('src/views/maintenance'))
      }
    ]
  }
];
