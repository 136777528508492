import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme, themeWithRtl } from './theme';
import { configureStore } from './store';
import routes from './routes';
import GoogleAnalytics from './components/GoogleAnalytics';
import CookiesNotification from './components/CookiesNotification';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';
import apollo from './utils/apollo';
import { ApolloProvider } from 'react-apollo';
import { StepProvider } from './providers/StepProvider';
import ToastProvider from './providers/ToastProvider';

const history = createBrowserHistory();
const store = configureStore();

function App() {
  const [direction, setDirection] = useState('ltr');

  return (
    <StoreProvider store={store}>
      <ApolloProvider client={apollo}>
        <ThemeProvider theme={direction === 'rtl' ? themeWithRtl : theme}>
          <StylesProvider direction={direction}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <StepProvider>
                <ToastProvider>
                  <Router history={history}>
                    {/*<CookiesNotification />*/}
                    {renderRoutes(routes)}
                  </Router>
                </ToastProvider>
              </StepProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </ApolloProvider>
    </StoreProvider>
  );
}

export default App;
